/* ==========
Version 1.0
Image Zoom Blocks For Squarespace
Copyright Will-Myers
========== */
.wm-zoom-container {
  --x-pos: 0;
  --y-pos: 0;
  overflow: hidden;
  user-select: none;
  height: 100%;
}
.wm-zoom-container img{
  top:0;
  left:0;
  object-position: center !important;
  transform: scale(1);
  transition: transform .2s ease,
    transform-origin .2s ease;
  transform-origin: center;
  -webkit-touch-callout: none;
  user-select:none;
}
.wm-zoom-container.active-zoom img{
  transform-origin: var(--x-pos) var(--y-pos) !important;
  transform: scale(var(--scale-amount, 2)) !important;
  transition: transform .2s ease;
}
[data-wm-image-zoom] .image-block-wrapper:after {
  pointer-events:none;
}

/*
 * If on a Non-Hover Device
*/
@media(hover:none){
  /* Prevent Zooming on Images with Links*/
  a.wm-zoom-container, a .wm-zoom-container {
    --scale-amount: 1 !important;
  }
  /* Prevent Cursor from Appearing*/
  .wm-zoom-container .zoom-follow-cursor {
    display:none !important;
  }
}


.wm-zoom-container.follow-cursor{
  cursor:none;
}
.wm-zoom-container.follow-cursor .zoom-follow-cursor{
  display:inline-block;
}
.zoom-follow-cursor.circle {
  display: none;
  position:absolute;
  width: var(--cursor-size, 10px);
  height: var(--cursor-size, 10px);
  background-color: var(--cursor-color, white);
  border-radius: 50%;
  top: 0;
  left: 0;
}

/*For Gallery => Grid:Simple Sections*/
.gallery-grid-item .wm-zoom-container{
  height: 0 !important;
}

/*For Product Section Images*/
.products-list .grid-item[data-wm-image-zoom] .grid-image{
  pointer-events: initial;
}

/*For Product Details Pages*/
.ProductItem-gallery-slides-item[data-wm-image-zoom] .product-image-zoom-duplicate{
  display:none !important;
}


/*For Lightbox Sections*/
[data-wm-image-zoom][data-lightbox-section-id] .gallery-lightbox-control{
  flex-grow:unset;
}
[data-wm-image-zoom][data-lightbox-section-id] .gallery-lightbox-item{
  pointer-events:unset;
}
[data-wm-image-zoom][data-lightbox-section-id] .content-fit{
  display: flex;
  align-items: center;
  justify-content:center;
}
[data-wm-image-zoom][data-lightbox-section-id] .gallery-lightbox-item-src{
  width:auto;
}
[data-wm-image-zoom][data-lightbox-section-id] .gallery-lightbox-item-src.landscape{
  height: auto;
  width: 100%;
}

[data-wm-image-zoom][data-lightbox-section-id] img {
  width:100% !important;
  height:auto !important;
  max-height:100%;
}
